//
// Layout Config
//

// Layout
//$kt-aside-default-width: 265px;
$kt-aside-default-width: 270px;
//$kt-aside-minimize-width: 70px;
$kt-aside-minimize-width: 40px;
$kt-aside-offcanvas-width: 270px;

// Layout Skins
$kt-layout-skins: (
  //dark: #1e1e2d,
    dark: #000,
  light: #ffffff
);

// content bg color
$kt-content-bg-color: #f2f3f8;

// Border Radius
$kt-border-radius: 4px;

// Page padding
$kt-page-padding: (
  desktop: 25px,
  mobile: 15px
);

// Page container width
$kt-page-container-width: 1380px;
